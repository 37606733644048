































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { User, Users, Global, Projects } from '@/store';
import EditUserPropertyDialog from '@/pages/settings/components/EditUserPropertyDialog.vue';
import API, { Types } from '@/modules/API';
import PropEditor from '@/components/PropEditor.vue';

@Component({
  components: {
    EditUserPropertyDialog,
    PropEditor
  }
})
export default class UserConfigurations extends Vue {

    @Global.State('lang') lang;
    @Global.Action('throwNotify') throwNotify;
    @User.State('properties') userProperties;
    @User.State('project') project;
    @User.State('user_id') user_id;
    @User.State('default_map') default_map;
    @User.Mutation('setDefaultMap') setDefaultMap;
    @User.Action('updateUserProperty') updateUserProperty;
    @Users.State('list') usersList;

    items = [];
    user_properties_map = new Map();
    editDialogIsOpen = false;
    selected_item = null;
    is_close_disabled = false;
    first_default_map_value = '';

    async created(){
        const default_map_value = this.default_map ? this.default_map.toLowerCase() : '';
        this.first_default_map_value = default_map_value;
        this.items = [
            {
                id: '1',
                property_name: 'Default Map',
                property_text: this.getDefaultMapText(default_map_value),
                property_value: default_map_value
            }
        ];
        
        if (!this.default_map){
            const item_index = this.items.findIndex((item) => item.property_name.toLowerCase() === 'default map');
            this.selected_item = this.items[item_index];
            this.is_close_disabled = true;
            this.editDialogIsOpen = true;
        }
    }

    getDefaultMapText(value){
        return value ? value === 'basic' ? 'Basic Map' : 'Advanced Map' : '';
    }

    editDialogClicked(item){
        this.editDialogIsOpen = true;
        this.selected_item = item;
    }

    editDialogClosed(){
        this.editDialogIsOpen = false;
        this.selected_item = null;
    }

    handleAction(action, value){
        this[action](value);
    }

    editDefaultMap(default_map){
        const dashboard_settings = this.userProperties.find((property) => property.name === 'dashboard.settings');
        const settings_prop_data = {
            name: 'dashboard.settings',
            value: null
        };

        settings_prop_data.value = dashboard_settings && dashboard_settings.value 
            ? {...dashboard_settings.value, map: default_map}
            : { lang: this.lang, map: default_map};

        this.updateUserProperty(settings_prop_data).then(() => {
            this.throwNotify({
                type: 'success',
                title: `${this.$t('Success')!}`,
                text: this.$t('Default map successfully saved')
            });
            this.setDefaultMap(default_map);
            const item_index = this.items.findIndex((item) => item.property_name.toLowerCase() === 'default map');
            Vue.set(this.items[item_index], 'property_text', this.getDefaultMapText(default_map));
            Vue.set(this.items[item_index], 'property_value', default_map);
            this.editDialogClosed();
            if (!this.first_default_map_value) {
                this.$router.push('/fullViewReport');
            }
        }).catch(() => {
            this.throwNotify({
                type: 'error',
                title: `${this.$t('Error')!}`,
                text: this.$t(`Couldn't update data`)
            });
            this.editDialogClosed();
        });
    }

    get headers(){
        return [
            {
                text: this.$t('Field'),
                value: 'property_name',
                class: [this.lang === 'en' ? 'text-xs-left body-2' : 'text-xs-right body-2']
            },
            {
                text: this.$t('Value'),
                value: 'property_value',
                class: [this.lang === 'en' ? 'text-xs-left body-2' : 'text-xs-right body-2']
            },
            {
                text: this.$t('Action'),
                value: 'id',
                sortable: false,
                class: [this.lang === 'en' ? 'text-xs-left body-2' : 'text-xs-right body-2']
            }
        ];
    }

}
