

















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';
import DefaultMapInfo from './DefaultMapInfo.vue';

@Component({
    components: {
        DefaultMapInfo
    }
})
export default class EditUserPropertyDialog extends Vue {

    @Global.State('lang') lang;

    @Prop() item;
    @Prop() is_close_disabled;

    dialog = true;
    value = '';

    created(){
        if (this.item.property_value) this.value = this.item.property_value;
    }

    get dialog_width(){
       return this.$vuetify.breakpoint.smAndDown 
        ? this.is_close_disabled 
            ? '70vw'
            : '60vw'
        : this.$vuetify.breakpoint.xlOnly
            ? this.is_close_disabled 
                ? '30vw'
                : '20vw'
            : this.is_close_disabled 
                ? '40vw'
                : '30vw';
    }

    get mapTypes(){
        return [{text: this.$t('Basic Map'), value: 'basic'}, {text: this.$t('Advanced Map'), value: 'advanced'}];
    }

}
