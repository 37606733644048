import { render, staticRenderFns } from "./UserConfigurations.vue?vue&type=template&id=99d20706&scoped=true"
import script from "./UserConfigurations.vue?vue&type=script&lang=ts"
export * from "./UserConfigurations.vue?vue&type=script&lang=ts"
import style1 from "./UserConfigurations.vue?vue&type=style&index=1&id=99d20706&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99d20706",
  null
  
)

export default component.exports